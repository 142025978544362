import UsersService from "../services/users.service.js";
import moment from "moment";

const EMPTY_PASSWORD = "********";
const state = {
  currentLkVersion: null,
  loader: false,
  userSnackbar: false, // показываем после любого сохранения изменений о пользователе
  users: [],
  privsIndex: [],
  currentPrivs: [],
  clientChannels: [],
  clientCities: [],
  clientPeriods: [],
  clientTags: [],
  clientSources: [],
  citiesList: [],
  channelsList: [],
  sourcesList: [],
  tagsList: [],
  selectedUsers: [],
  citiesFilter: "",
  channelsFilter: "",
};
const getters = {
  getCurrentLkVersion: (state) => state.currentLkVersion,
  getUsers: (state) => state.users,
  getUser: (state) => (userId) => {
    const user = state.users.find(item=>item.id==userId);
    return user;
  },
  getUserSnackbar: (state) => state.userSnackbar,
  getCitiesFilter: (state) => state.citiesFilter,
  getChannelsFilter: (state) => state.channelsFilter,
  getSelectedUsers: (state) => state.selectedUsers,
  getPrivsIndex: (state) => state.privsIndex,
  getReportsPrivs: (state) => state.privsIndex.filter(item => item.priv_parent == 'report'),
  getSectionsPrivs: (state) => state.privsIndex.filter(item => item.priv_parent == 'section'),
  getSourcesPrivs: (state) => state.privsIndex.filter(item => item.priv_parent == 'source'),
  getCurrentPrivs: (state) => state.currentPrivs,
  getTagsList: (state) => state.tagsList,
  getClientTags: (state) => state.clientTags,
  getClientSources: (state) => state.clientSources,
  getClientChannels: (state) => {
    if (state.channelsFilter == "") return state.clientChannels;
    const filteredChannels = state.channelsList.filter(item => {
      return item?.name?.toLowerCase().includes(state.channelsFilter);
    }).map(item=>item.id);
    return state.clientChannels.filter(item => filteredChannels.includes(item.id) || item.id===null);
  },
  getClientCities: (state) => {
    if (state.citiesFilter == "") return state.clientCities;
    const filteredCities = state.citiesList.filter(item => {
      return item?.name?.toLowerCase().includes(state.citiesFilter);
    }).map(item => item.id);
    return state.clientCities.filter(item => filteredCities.includes(item.id) || item.id === null);
  },
  getClientPeriods: (state) => state.clientPeriods,
  getAdminChannelsList: (state) => state.channelsList,
  getAdminCitiesList: (state) => state.citiesList,
  getAdminSourcesList: (state) => state.sourcesList,
  hasActivePeriod: (state) => {
    const now = moment().format("YYYY-MM-DD HH:mm:ss");
    for (const period of state.clientPeriods) {
      const end = `${period.end} 23:59:59`;
      if (end > now) {
        const diff = moment(end).diff(moment(), "days") + 1;
        return diff;
      }
    }
    return false;
  }
};
const actions = {
  async UpdateUsersTags({ commit, getters }, {user_id, tags}) {
    const user = getters.StateUser;
    if (!user) return;
    commit("showLoader");
    await UsersService.UpdateUserTags({ user_id, tags });
    commit("hideLoader");
    commit("showUserSnackbar");
  },
  async GetUsers({ commit, getters }) {
    const user = getters.StateUser;
    if (!user) return;
    commit("showLoader");
    const data = await UsersService.getUsers({user_id: user.uid});
    commit("setUsers", data.users);
    commit("setCurrentLkVersion", data.currentLkVersion);
    commit("hideLoader");
  },
  async UpdatePriv({ commit, getters }, {id, privId, privValue}) {
    const user = getters.StateUser;
    if (!user) return;
    commit("showLoader");
    await UsersService.UpdateUserPriv({ user_id: id, priv_id: privId, priv_value: privValue });
    commit("hideLoader");
    commit("showUserSnackbar");
  },
  async CopyAccountPrivs({ commit, getters }, { user_id, minActive, maxActive, users }) {
    const user = getters.StateUser;
    if (!user) return;
    for (const user of users) {
      user.minActive = minActive;
      user.maxActive = maxActive;
    }
    commit("showLoader");
    await UsersService.CopyUserPrivs({ user_id, users });
    commit("hideLoader");
    commit("showUserSnackbar");
  },
  async UpdateSourcesPriv({ commit, getters }, { id, sourceId, sourceValue }) {
    const user = getters.StateUser;
    if (!user) return;
    commit("showLoader");
    await UsersService.UpdateUserSourcePriv({ user_id: id, source_id: sourceId, source_value: sourceValue });
    commit("hideLoader");
    commit("showUserSnackbar");
  },
  async GetUserAllPrivs({ commit, getters }, user_id) {
    const user = getters.StateUser;
    if (!user) return;
    commit("showLoader");
    const data = await UsersService.GetUserAllPrivs({ user_id });
    commit("hideLoader");
    commit("setUserAllPrivs", data);
  },
  async SaveUser({ commit, getters }, targetUser) {
    const user = getters.StateUser;
    if (!user) return;
    commit("showLoader");
    const data = await UsersService.SaveUser({user_id: user.uid, targetUser});
    if (Array.isArray(data?.users)) {
      const userData = data.users[0];
      userData.userId = userData.id;
      delete userData.id;
      commit("updateUser", userData);
    }
    commit("hideLoader");
    commit("showUserSnackbar");
  },
  async KickUser({ commit, getters }, targetUser) {
    const user = getters.StateUser;
    if (!user) return;
    const data = await UsersService.KickUser({user_id: user.uid, targetUser});
    if (Array.isArray(data?.users)) {
      const userData = data.users[0];
      userData.userId = userData.id;
      delete userData.id;
      commit("updateUser", userData);
      commit("showUserSnackbar");
    }
  },
  async AddUser({ commit, getters }, { name }) {
    const user = getters.StateUser;
    if (!user) return;
    const data = await UsersService.AddUser({user_id: user.uid, name});
    console.log(data);
    commit("addUser", data);
    commit("showUserSnackbar");
  },
  async SaveUserPeriod({ commit, getters }, { user_id, period }) {
    const user = getters.StateUser;
    if (!user) return;
    commit("showLoader");
    const data = await UsersService.SaveUserPeriod({ user_id, period });
    if (data.line_id > 0) {
      commit("updateUserPeriodLineId", {old_line_id: period.line_id, new_line_id: data.line_id});
    }
    commit("hideLoader");
    commit("showUserSnackbar");
  },
  async SaveUserCity({ commit, getters }, { user_id, city }) {
    const user = getters.StateUser;
    if (!user) return;
    commit("showLoader");
    const data = await UsersService.SaveUserCity({ user_id, city });
    if (data.line_id > 0) {
      commit("updateUserCityLineId", {old_line_id: city.line_id, new_line_id: data.line_id});
    }
    commit("hideLoader");
    commit("showUserSnackbar");
  },
  async SaveUserChannel({ commit, getters }, { user_id, channel }) {
    const user = getters.StateUser;
    if (!user) return;
    commit("showLoader");
    const data = await UsersService.SaveUserChannel({ user_id, channel });
    if (data.line_id > 0) {
      commit("updateUserChannelLineId", { old_line_id: channel.line_id, new_line_id: data.line_id});
    }
    commit("hideLoader");
    commit("showUserSnackbar");
  },
};
const mutations = {
  setCitiesFilter(state, { str }) {
    state.citiesFilter = str.toLowerCase();
  },
  setChannelsFilter(state, { str }) {
    state.channelsFilter = str.toLowerCase();
  },
  addSelectedUser(state, { user }) {
    state.selectedUsers.push(user);
  },
  clearSelectedUsers(state) {
    state.selectedUsers = [];
  },
  updateUserPeriodLineId(state, { old_line_id, new_line_id }) {
    console.log(old_line_id, new_line_id);
    const period = state.clientPeriods.find(item=>item.line_id==old_line_id);
    period.line_id = new_line_id;
  },
  updateUserCityLineId(state, { old_line_id, new_line_id }) {
    const city = state.clientCities.find(item=>item.line_id==old_line_id);
    city.line_id = new_line_id;
  },
  updateUserChannelLineId(state, {old_line_id, new_line_id}) {
    const channel = state.clientChannels.find(item=>item.line_id==old_line_id);
    channel.line_id = new_line_id;
  },
  addClientPeriod(state) {
    const newPeriod = {
      end: null,
      start: null,
      id: null,
      line_id: `-${state.clientPeriods.length + 1}`,
    };
    state.clientPeriods.push(newPeriod);
  },
  addClientCity(state) {
    const newCity = {
      end: null,
      start: null,
      id: null,
      name: null,
      line_id: `-${state.clientCities.length + 1}`,
    };
    state.clientCities.push(newCity);
  },
  addClientChannel(state) {
    const newChannel = {
      end: null,
      start: null,
      id: null,
      name: null,
      line_id: `-${state.clientChannels.length + 1}`,
    };
    console.log(newChannel);
    state.clientChannels.push(newChannel);
  },
  deleteClientPeriod(state, {line_id}) {
    state.clientPeriods = state.clientPeriods.filter(item=>item.line_id!=line_id)
  },
  deleteClientCity(state, {line_id}) {
    state.clientCities = state.clientCities.filter(item=>item.line_id!=line_id)
  },
  deleteClientChannel(state, {line_id}) {
    state.clientChannels = state.clientChannels.filter(item=>item.line_id!=line_id)
  },
  setUserAllPrivs(state, params) {
    state.clientTags = params.clientTags;
    state.tagsList = params.tagsList;
    state.currentPrivs = params.privs;
    state.clientChannels = params.clientChannels;
    state.clientCities = params.clientCities;
    state.clientSources = params.privs
      .filter(item => item.id == 1)
      .map(item => item.value);
    state.clientPeriods = params.periods;
    state.channelsList = [{ id: null, name: "", start: null, end: null }, ...params.channelsList];
    state.citiesList = [{ id: null, name: "", start: null, end: null }, ...params.citiesList];
    state.sourcesList = params.sourcesList
      .map(item=>{
        const checked = state.clientSources.includes(item.id);
        return { ...item, checked }
      });
    state.privsIndex = params.privs_index
      .map((item) => {
        const selectedItem = params.privs.find(priv=>priv.id==item.id);
        const selected = selectedItem ? selectedItem.value : item.default_value;
        return {...item, selected: selected - item.priv_min}
      });
     console.log(state.privsIndex)
  },
  clearUsersEdits(state) {
    for (const user of state.users) {
      user.password = EMPTY_PASSWORD;
      user.edit = null;
    }
  },
  clearUsers(state) {
    state.users = [];
  },
  updateUser(state, params) {
    const user = state.users.find((item) => item.id == params.userId);
    if (!user) return;
    delete params.userId;
    for (const key in params) {
      user[key] = params[key];
      if (key == "results" || key == "status" || key == "qry") {
        //
      } else {
        // state.paramsChanged = true;
      }
    }
    if (!("password" in params)) user.password = EMPTY_PASSWORD;
    user.oldName = user.name;
    user.oldLogin = user.login;
    user.oldMinActive = user.minActive;
    user.oldMaxActive = user.maxActive;
  },
/*   hideUsersPasswords(state) {
    console.log('hide')
    for (const user of state.users) user.password = EMPTY_PASSWORD;
  }, */
  showUserSnackbar(state) {
    state.userSnackbar = true;
    setTimeout(() => {
      state.userSnackbar = false;
    }, 2000);
  },
  hideUserSnackbar(state) {
    state.userSnackbar = false;
  },
  showUsersLoader(state) {
    state.loader = true;
  },
  hideUsersLoader(state) {
    state.loader = false;
  },
  setUsers(state, data) {
    for (const item of data) {
      item.edit = null;
      item.password = EMPTY_PASSWORD;
      item.oldName = item.name;
      item.oldLogin = item.login;
      item.oldMinActive = item.minActive;
      item.oldMaxActive = item.maxActive;
      item.lk_version_txt = item.lk_version;
      if (item.lk_version!==null) item.lk_version_txt = `v${item.lk_version}`;
    }
    state.users = data;
  },
  setCurrentLkVersion(state, data) {
    state.currentLkVersion = data;
  },
  addUser(state, data) {
    if (data.users && Array.isArray(data.users)) {
      const newUser = {
        ...data.users[0],
        edit: null,
        password: EMPTY_PASSWORD,
        oldName: data.users[0].name,
        oldLogin: data.users[0].login,
        oldMinActive: data.users[0].minActive,
        oldMaxActive: data.users[0].maxActive,
      };
      state.users.push(newUser);
    }
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
